<!--
 * @Author: lbh
 * @Date: 2022-07-02 10:51:18
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-12 16:19:13
 * @Description: 底部
-->
<template>
  <div class="footer-box px-footer-box">
    <div class="web-box">
      <div class="items">
        <div
          class="item"
          v-for="(item, index) in configs.menus"
          :key="index"
        >
          <div
            class="title"
            v-html="$util.rex.getHtml(item[`${nowL.code}title`] || item.title)"
          ></div>
          <template v-for="(it_, in_) in item.child">
            <div
              :key="in_"
              v-if="!it_.showImg"
              class="cell"
            >
              <span
                v-if="it_[`${nowL.code}title`] || it_.title"
                v-html="$util.rex.getHtml(it_[`${nowL.code}title`] || it_.title)"
              >{{ it_.title }}</span>
              <span
                v-if="it_[`${nowL.code}value`] || it_.value"
                v-html="$util.rex.getHtml(it_[`${nowL.code}value`] || it_.value)"
                :class="it_.go ? 'pointer' :''"
                @click="$util.route.go(it_.go)"
              ></span>
            </div>
          </template>
          <div class="img-box">
            <template v-for="(it_, in_) in item.child">
              <img
                v-if="it_.showImg"
                :key="in_"
                style="height:24px;margin-right:18px;"
                :class="it_.go ? 'pointer' :''"
                :src="it_.img"
                @click="$util.route.go(it_.go)"
              />
            </template>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="bottom">{{ configs.footerText }}</div>
      <div
        v-if="configs.agreement"
        class="agreement-box"
      >
        <div
          v-for="(item,index) in configs.agreement"
          :key="index"
          v-html="$util.rex.getHtml(item[`${nowL.code}title`] || item.title)"
          @click="goAgreement(index)"
        >
        </div>
      </div>
    </div>
    <div class="phone-box">
      <template v-for="(item, index) in configs.menus">
        <div
          v-if="index < configs.menus.length-1"
          class="item"
          :key="index"
        >
          <div>
            <!-- 渲染文字 -->
            <div
              class="cell"
              v-for="(it_, in_) in item.child"
              :key="in_"
            >
              <template v-if="!it_.showImg">
                <span
                  v-if="it_[`${nowL.code}title`] || it_.title"
                  v-html="$util.rex.getHtml(it_[`${nowL.code}title`] || it_.title)"
                ></span>
                <span
                  v-if="it_[`${nowL.code}value`] || it_.value"
                  v-html="$util.rex.getHtml(it_[`${nowL.code}value`] || it_.value)"
                  @click="$util.route.go(it_.go)"
                ></span>
              </template>
            </div>

          </div>

          <!-- 渲染圖標 -->
          <div
            v-if="index == 0"
            class="img-box"
          >

            <template v-for="(it_, in_) in configs.menus[configs.menus.length-1].child">
              <template v-if="!it_.showImg">
                <div
                  :key="in_"
                  class="cell"
                >
                  <span
                    v-if="it_[`${nowL.code}title`] || it_.title"
                    v-html="$util.rex.getHtml(it_[`${nowL.code}title`] || it_.title)"
                  ></span>
                  <span
                    v-if="it_[`${nowL.code}value`] || it_.value"
                    v-html="$util.rex.getHtml(it_[`${nowL.code}value`] || it_.value)"
                  ></span>
                </div>
              </template>
            </template>
            <div class="cell-img">
              <template v-for="(it_, in_) in configs.menus[configs.menus.length-1].child">
                <template v-if="it_.showImg">
                  <img
                    v-if="it_.showImg"
                    :key="in_"
                    style="height:24px;margin-right:18px;"
                    :src="it_.img"
                    @click="$util.route.go(it_.go)"
                  />
                </template>
              </template>
            </div>
          </div>

        </div>
      </template>
      <div class="line"></div>
      <!-- 隱私及條款 -->
      <div
        v-if="configs.agreement"
        class="agreement-box"
      >
        <div
          v-for="(item,index) in configs.agreement"
          :key="index"
          v-html="$util.rex.getHtml(item[`${nowL.code}title`] || item.title)"
          @click="goAgreement(index)"
        >
        </div>
      </div>
      <div class="bottom">{{ configs.footerText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'self-footer',
  props: {
    configs: {
      default () {
        return {};
      },
    },
  },
  data () {
    return {
      nowL: {
        code: 'HK',
        name: '繁體'
      }
    }
  },
  created () {
    this.initLang();
    this.$changeLang.set('footer', this.initLang)
  },
  methods: {
    initLang () {
      this.$idb.get('language').then(language => {
        let now_language = this.$storage.get('now_language')
        if (!now_language) now_language = 'HK';
        for (let i = 0; i < language.length; i += 1) {
          if (language[i].code == now_language) {
            this.nowL = language[i]
            break;
          }
        }
      })
    },
    goAgreement (index) {
      console.log(index);
      this.$util.route.go(`agreement/${this.$util.base64.btoa(index)}`)
    }
  }
};
</script>

<style lang="less" scoped>
.footer-box {
  background-color: #0f1422;
  .pointer {
    cursor: pointer;
  }
  .items {
    display: flex;
    justify-content: space-between;
    padding: 120px 360px 96px;
    .item {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 18px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        height: 24px;
        margin-bottom: 50px;
      }
      .cell {
        margin-bottom: 30px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8);
        &:nth-last-child(2) {
          margin-bottom: 0;
        }
      }
      .img-box {
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-top: auto;
        .cell {
          margin: 0;
        }
        img {
          &:first-of-type {
            margin-left: auto;
          }
          width: 24px;
          height: 24px !important;
        }
      }
    }
  }
  & /deep/ .el-divider {
    opacity: 0.3;
    background-color: #fff;
  }
  .web-box {
    padding-bottom: 110px;
  }
  .bottom {
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding-top: 28px;
  }
  .agreement-box {
    display: flex;
    color: rgba(255, 255, 255, 0.8);
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-top: 10px;
    user-select: none;
    div {
      margin-left: 20px;
      position: relative;
      cursor: pointer;
      &:first-child {
        margin-left: 0;
        &::after {
          content: "";
        }
      }
      &::after {
        position: absolute;
        content: "·";
        width: 20px;
        height: 20px;
        left: -20px;
        text-align: center;
      }
    }
  }
}
.phone-box {
  display: none;
}
@media screen and (max-width: 1000px) {
  .web-box {
    display: none;
  }
  .px-footer-box {
    .phone-box {
      display: block;
      padding: 20px 30px;
      .item {
        &:first-of-type {
          display: flex;
          position: relative;
          margin-bottom: 28px;
          div {
            .cell {
              &:last-of-type {
                margin: 0;
              }
            }
          }
          .img-box {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            flex: 1;
            margin: 0 0 0 24px;
            .cell-img {
              font-size: 0;
              margin-top: auto;
              margin-left: auto;
              img {
                width: 20px;
                height: 20px;
                border-radius: 2px !important;
                background-color: #fff;
              }
            }
          }
          &::after {
            position: absolute;
            content: "";
            left: -15px;
            right: -15px;
            height: 1px;
            background: rgba(255, 255, 255, 0.3);
            bottom: -14px;
          }
        }
        .cell {
          display: flex;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.8);
          margin-bottom: 8px;
        }
      }
      .line {
        position: relative;
        &::after {
          position: absolute;
          content: "";
          left: -16px;
          right: -16px;
          height: 1px;
          background-color: #ffffff;
          opacity: 0.3;
        }
      }
      .agreement-box {
        justify-content: flex-start;
        padding-top: 14px;
        margin: 0;
        font-size: 12px;
        div {
          margin-left: 20px;
          position: relative;
          cursor: pointer;
          &:first-child {
            margin-left: 0;
            &::after {
              content: "";
            }
          }
          &::after {
            position: absolute;
            content: "·";
            width: 20px;
            height: 20px;
            left: -20px;
            text-align: center;
          }
        }
      }
      .bottom {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 500;
        text-align: left;
        margin-top: 16px;
        padding: 0;
      }
      .img-box {
        display: flex;
        align-items: center;
        margin-top: 10px;
        img {
          &:first-of-type {
            margin-left: auto;
          }
          width: 20px;
          height: 20px !important;
        }
      }
    }
  }
}
@media screen and (min-width: 1000px) {
  .web-box {
    display: block;
  }
  .px-footer-box {
    .phone-box {
      display: none;
    }
  }
}

@media screen and (max-width: 500px) {
  .px-footer-box {
    .phone-box {
      padding: 20px 16px;
      .item {
        &:first-of-type {
          display: flex;
          position: relative;
          margin-bottom: 28px;
          div {
            .cell {
              &:last-of-type {
                margin: 0;
              }
            }
          }
          .img-box {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            flex: 1;
            margin: 0 0 0 24px;
            .cell-img {
              font-size: 0;
              margin-top: auto;
              margin-left: auto;
              img {
                width: 20px;
                height: 20px;
                border-radius: 2px !important;
                background-color: #fff;
              }
            }
          }
          &::after {
            position: absolute;
            content: "";
            left: -15px;
            right: -15px;
            height: 1px;
            background: rgba(255, 255, 255, 0.3);
            bottom: -14px;
          }
        }
        .cell {
          font-size: 12px;
        }
      }
      .img-box {
        img {
          &:last-child {
            margin: 0 !important;
          }
        }
      }
    }
    .agreement-box {
      justify-content: flex-start;
      padding-top: 14px;
      margin: 0;
      font-size: 12px;
      div {
        margin-left: 20px;
        position: relative;
        cursor: pointer;
        &:first-child {
          margin-left: 0;
          &::after {
            content: "";
          }
        }
        &::after {
          position: absolute;
          content: "·";
          width: 20px;
          height: 20px;
          left: -20px;
          text-align: center;
        }
      }
    }
    .bottom {
      margin-top: 8px !important;
    }
  }
}
</style>
